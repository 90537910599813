import React from 'react';
import { Row } from 'emotion-flex';
import { ContentfulHeadingAndText } from '../../graphql-types';
import presets from '../../styles/presets';
import { css } from '@emotion/core';
import PressPerk from '../../components/Press/pressPerk';
import pressBackground from '../../assets/images/press-header-background.svg';
import headerIcon1 from '../../assets/images/press-header-icon1.svg';
import headerIcon2 from '../../assets/images/press-header-icon2.svg';
import headerIcon3 from '../../assets/images/press-header-icon3.svg';
import headerIcon4 from '../../assets/images/press-header-icon4.svg';

interface PressNumbersProps {
  items: ContentfulHeadingAndText[];
}

const PressNumbers: React.FC<PressNumbersProps> = ({ items }) => {
  return (
    <>
      <div
        css={css`
          display: flex;
          background-image: url(${pressBackground});
          background-size: cover;
          min-height: 1000px;
          padding-top: 100px;
          padding-bottom: 200px;
          margin-bottom: 100px;

          ${presets.md} {
            padding-top: 80px;
            padding-bottom: 180px;
          }
          ${presets.lg} {
            padding-top: 80px;
            padding-bottom: 160px;
          }
          ${presets.xl} {
            padding-top: 80px;
            padding-bottom: 100px;
          }

          @media (min-width: 1930px) {
            min-height: 45vw;
          }
        `}
      >
        <Row
          css={{
            width: '100%',
            height: 'auto',
            marginBottom: '350px',
            alignContent: 'center',
          }}
        >
          <PressPerk
            image={headerIcon1}
            heading={items[0].heading}
            text={items[0].text.childMarkdownRemark.rawMarkdownBody}
          />
          <PressPerk
            image={headerIcon2}
            heading={items[1].heading}
            text={items[1].text.childMarkdownRemark.rawMarkdownBody}
          />
          <PressPerk
            image={headerIcon3}
            heading={items[2].heading}
            text={items[2].text.childMarkdownRemark.rawMarkdownBody}
          />
          <PressPerk
            image={headerIcon4}
            heading={items[3].heading}
            text={items[3].text.childMarkdownRemark.rawMarkdownBody}
          />
        </Row>
      </div>
    </>
  );
};

export default PressNumbers;
