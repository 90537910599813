import React from 'react';
import LinkWithArrow from '../Perks/LinkWithArrow';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import { ContentfulImageTextAndLink } from '../../graphql-types';

interface PerkCardWithLinkProps {
  background: string;
  item: ContentfulImageTextAndLink;
}

const PressLogoCardWithLink: React.FC<PerkCardWithLinkProps> = ({
  background,
  item: { image, shortText, linkText = 'Read Article', linkHref = '/' },
}) => {
  return (
    <div
      css={{
        flex: '1 1 auto',
        padding: '24px 20px',
        textAlign: 'left',
        borderRadius: 10,
        marginBottom: '1rem',
        backgroundColor: background || '#E2E8F7',
      }}
    >
      <img
        alt={shortText}
        src={image.fluid.src}
        css={{ maxWidth: 200, maxHeight: 40 }}
      />
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontSize: '0.875rem',
          fontWeight: 400,
          color: hexToRgba('#160B2C', 0.8),
          b: {
            fontWeight: 400,
          },
        }}
      >
        {shortText}
      </p>
      <LinkWithArrow to={linkHref} text={linkText} variant="normal" />
    </div>
  );
};

export default PressLogoCardWithLink;
