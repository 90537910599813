import React from 'react';
import greenTick from '../../assets/images/press-founders-icon.png';
import presets from '../../styles/presets';
import { SYSTEM_FONTS } from '../../styles/typography';

const FoundersTick = ({ simpleText }: { simpleText: string }) => {
  return (
    <div
      css={{
        display: 'flex',
        padding: '0 15px',
        maxWidth: 350,

        [presets.md]: { padding: 0 },
      }}
    >
      <img
        src={greenTick}
        alt="green tick"
        css={{ width: 26, height: 26, marginRight: 18 }}
      />
      <p
        css={{
          fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
          fontSize: 17,
          fontWeight: 500,
          color: '#160B2C',
          textAlign: 'left',
        }}
      >
        {simpleText}
      </p>
    </div>
  );
};

export default FoundersTick;
