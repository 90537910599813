import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';

import { SEO } from '../components';
import {
  ContentfulPressPage,
  ContentfulHeadingAndText,
  ContentfulText,
} from '../graphql-types';

import { withLayout } from '../components/Layout';
import HeadingSection from '../components/HeadingSection/HeadingSection';
import PressNumbers from '../components/Press/pressNumbers';
import PressSocialMediaImage from '../components/Press/pressSocialMediaImage';
import PressSocialMediaBlocks from '../components/Press/pressSocialMediaBlocks';
import Founders from '../components/Press/pressFounders';
import PressKitAndContact from '../components/Press/pressKitAndContact';
import PressPageLogos from '../components/Press/pressPageLogos';

const styles = {
  heroSection: css`
    margin-top: 118px;
    margin-bottom: 70px;
  `,
  headingSpace: css`
    margin-top: 160px;
  `,
};

interface Props {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    press: ContentfulPressPage;
  };
}

const PressPage: React.FC<Props> = ({
  pathContext: { locale },
  data: { press },
}) => {
  return (
    <>
      <SEO title={press.seoTitle} lang={locale} />
      <div css={styles.heroSection}>
        <HeadingSection {...press.heroSection} />
      </div>
      <PressNumbers items={press.numbers} />
      <Founders
        headerItem={press.founders[0] as ContentfulHeadingAndText}
        tickItems={press.founders.slice(1) as [ContentfulText]}
      />
      <PressKitAndContact
        reachOutItem={press.reachOutSection}
        pressKitItem={press.pressKitSection}
      />
      <div css={styles.headingSpace} />
      <HeadingSection {...press.socialMediaSection} />
      <PressSocialMediaBlocks items={press.socialMediaLinks} />
      <PressSocialMediaImage />
      <div css={styles.headingSpace} />
      <HeadingSection {...press.pressHero} />
      <PressPageLogos items={press.pressLogosSection} />
    </>
  );
};

export const pressPageQuery = graphql`
  query pressPage($locale: String!) {
    press: contentfulPressPage(node_locale: { eq: $locale }) {
      seoTitle
      # seoDescription
      # seoKeywords
      heroTitle
      heroText {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 324) {
          ...GatsbyContentfulFluid
        }
      }
      heroSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      reachOutSection {
        id
        heading
        shortText
        linkHref
        linkText
      }
      pressKitSection {
        id
        heading
        shortText
        linkHref
        linkText
      }
      socialMediaLinks {
        socialMediaName
        url
      }
      socialMediaSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      numbers {
        id
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      founders {
        ... on ContentfulHeadingAndText {
          id
          heading
          text {
            childMarkdownRemark {
              excerpt(pruneLength: 500)
            }
          }
        }
        ... on ContentfulText {
          id
          simpleText
        }
      }
      pressHero {
        prefix
        title
      }
      pressLogosSection {
        id
        image {
          fluid(maxWidth: 324) {
            ...GatsbyContentfulFluid
          }
        }
        shortText
        linkHref
        linkText
      }
      downloadItems {
        id
        title
        file {
          file {
            url
          }
        }
      }
      pressStatementsTitle
      pressReleases {
        id
        title
        slug
        date(formatString: "DD. MMM YYYY", locale: $locale)
        text {
          childMarkdownRemark {
            excerpt(pruneLength: 190)
          }
        }
      }
      newsTitle
      newsItems {
        id
        date(formatString: "DD. MMM YYYY", locale: $locale)
        referrer
        title
        url
        description
        thumbnail {
          fixed(width: 480, height: 340) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`;

export default withLayout(PressPage, { isNewLayout: true });
