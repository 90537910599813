import React from 'react';
import { Row, Col } from 'emotion-flex';
import Container from '../Homepage/Container';
import presets from '../../styles/presets';
import hexToRgba from 'hex-to-rgba';
import { ContentfulImageTextAndLink } from '../../graphql-types';
import PressLogoCardWithLink from './pressLogoCardWithLink';

interface LogosProps {
  items: ContentfulImageTextAndLink[];
}

const Logos: React.FC<LogosProps> = ({ items }) => {
  const background = hexToRgba('#489F9D', 0.1);
  return (
    <>
      {items && (
        <Container css={{ marginTop: 96, marginBottom: 192 }}>
          <Row>
            <Col xs={12} lg={10} lgOffset={1}>
              <Row>
                {items.map((item) => (
                  <Col
                    key={item.id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    css={{
                      [presets.sm]: {
                        display: 'flex',
                      },
                    }}
                  >
                    <PressLogoCardWithLink {...{ item, background }} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Logos;
