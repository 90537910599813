import React from 'react';
import { Col } from 'emotion-flex';
import { ContentfulHeadingAndText, ContentfulText } from '../../graphql-types';
import presets from '../../styles/presets';
import { css } from '@emotion/core';
import foundersBackground from '../../assets/images/press-founders-background.svg';
import { SYSTEM_FONTS } from '../../styles/typography';
import hexToRgba from 'hex-to-rgba';
import PressFounders from '../../assets/images/press-founders.png';
import FoundersTick from '../Employers/FoundersTick';

const styles = {
  background: css`
    background-image: url(${foundersBackground});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    min-height: 921px;
    background-size: cover;
    margin-bottom: 102px;
    padding-top: 108px;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;

    margin-top: -500px;

    ${presets.lg} {
      flex-direction: row;
      padding-top: 126px;
    }

    @media (min-width: 1930px) {
      padding-top: 10vw;
      min-height: 60vw;
    }
  `,
};

interface FoundersTickProps {
  simpleText: string;
}

interface FoundersProps {
  headerItem: ContentfulHeadingAndText;
  tickItems: [ContentfulText];
}

const Founders: React.FC<FoundersProps> = ({ headerItem, tickItems }) => {
  const heading = headerItem.heading;
  const desc = headerItem.text.childMarkdownRemark.excerpt;
  return (
    <>
      <div css={styles.background}>
        <Col
          lg={6}
          css={{
            display: 'flex',
            justifyContent: 'center',
            minWidth: 100,

            [presets.lg]: {
              justifyContent: 'flex-end',
              minWidth: 500,
            },
          }}
        >
          <img src={PressFounders} alt="Founders" />
        </Col>
        <Col
          lg={6}
          css={{
            paddingLeft: 60,
            maxWidth: 500,
          }}
        >
          <p
            css={{
              fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
              color: '#160B2C',
              fontSize: 28,
              fontWeight: 500,
              marginLeft: 5,
              maxWidth: 500,
            }}
          >
            {heading}
          </p>
          <p
            css={{
              fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 14,
              color: hexToRgba('#000', 0.7),
              maxWidth: 500,
            }}
          >
            {desc}
          </p>
          {tickItems.map((item) => (
            <FoundersTick simpleText={item.simpleText} key={item.id} />
          ))}
        </Col>
      </div>
    </>
  );
};

export default Founders;
