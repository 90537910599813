import React from 'react';
import { css } from '@emotion/core';
import socialMedia from '../../assets/images/press-social-media.png';
import presets from '../../styles/presets';

const styles = {
  image: css`
    min-height: 260px;
    justify-content: center;
  `,
};

const PressSocialMediaImage: React.FC = () => {
  return (
    <div
      css={{
        marginTop: '86px',
        display: 'flex',
        marginLeft: '-62%',
        [presets.lg]: {
          marginLeft: '0px',
        },
      }}
    >
      <div css={styles.image}>
        <img alt="Press social media" src={socialMedia} loading="lazy" />
      </div>
    </div>
  );
};

export default PressSocialMediaImage;
