import React from 'react';
import { ContentfulColorfulBoxes } from '../../graphql-types';
import { Container, Col } from 'emotion-flex';
import hexToRgba from 'hex-to-rgba';
import PerkCardWithLink from '../Perks/PerkCardWithLink';
import presets from '../../styles/presets';
import { css } from '@emotion/core';

const styles = {
  perkCard: css`
    min-width: 100px;
    max-width: 450px;

    ${presets.md} {
      min-width: 300px;
    }
  `,
};

interface PressKitAndContactProps {
  reachOutItem: ContentfulColorfulBoxes;
  pressKitItem: ContentfulColorfulBoxes;
}

const PressKitAndContact: React.FC<PressKitAndContactProps> = ({
  reachOutItem,
  pressKitItem,
}) => {
  return (
    <Container
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 100,
        marginBottom: 100,
        maxWidth: 1200,
        [presets.sm]: {
          flexDirection: 'row',
        },
      }}
    >
      <Col css={styles.perkCard}>
        <PerkCardWithLink
          background={hexToRgba('#489F9D', 0.15)}
          item={reachOutItem}
        />
      </Col>
      <Col css={styles.perkCard}>
        <PerkCardWithLink background={'#FEF7E7'} item={pressKitItem} />
      </Col>
    </Container>
  );
};

export default PressKitAndContact;
